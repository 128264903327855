import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditRootCouse: null,
            auditRootCouseStatus: null,
        }
    },
    mutations: {
        setAuditRootCouse(state, payload) {
            state.auditRootCouse = payload
        },
        setAuditRootCouseStatus(state, payload) {
            state.auditRootCouseStatus = payload
        },
    },
    actions: {
        setAuditRootCouse({ commit }, params) {
            commit('setAuditRootCouse', params)
        },
        setAuditRootCouseStatus({ commit }, params) {
            commit('setAuditRootCouseStatus', params)
        },
        async getAuditRootCouse({ commit, state }, params) {
            return await Api().get(
                `audit-root-causes${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditRootCouse({ commit, state }, params) {
            return await Api().post(
                `audit-root-causes`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditRootCouse({ commit, state }, params) {
            return await Api().put(
                `audit-root-causes/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditRootCouse({ commit, state }, params) {
            return await Api().delete(
                `audit-root-causes/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditRootCouse(state) {
            return state.auditRootCouse;
        },
        getAuditRootCouseStatus(state) {
            return state.auditRootCouseStatus;
        },
    }
}