import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            menuList: null,
            menuListStatus: null,
        }
    },
    mutations: {
        setMenuList(state, payload) {
            state.menuList = payload
        },
        setMenuListStatus(state, payload) {
            state.menuListStatus = payload
        },
    },
    actions: {
        setMenuList({ commit }, params) {
            commit('setMenuList', params)
        },
        setMenuListStatus({ commit }, params) {
            commit('setMenuListStatus', params)
        },
        async getMenuLists({ commit, state }, params) {
            return await Api().get(
                `menu-lists${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getMenuItems({ commit, state }, params) {
            return await Api().get(
                `menu-items${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async editMenu({ commit, state }, params) {
            return await Api().put(
                `/menu-lists/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getMenuList(state) {
            return state.menuList;
        },
        getMenuListStatus(state) {
            return state.menuListStatus;
        },
    }
}