<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="/addRequestDocument" @submit.prevent="handleSubmit">
      <DxSelectBox
        class="custom-form"
        :data-source="requestReasonOptions"
        label="Pilih Keperluan"
        value-expr="name"
        display-expr="name"
        v-model="requestDocument.request_reason"
        :is-required="true"
        :read-only="reqReason"
        @valueChanged="changeReqReason"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxSelectBox>
      <DxSelectBox
        v-if="disabledForm"
        class="custom-form"
        :data-source="unitBisnisOptions"
        label="Pencarian Dokumen Berdasarkan Unit Bisnis"
        value-expr="id"
        display-expr="name"
        :is-required="true"
        v-model="valueUnitBisnis"
        @valueChanged="changeUnitBisnis"
        :read-only="readOnlyUnit"
      >
      </DxSelectBox>
      <DxSelectBox
        v-if="disabledForm"
        class="custom-form"
        :data-source="departmentOptions"
        label="Pencarian Dokumen Berdasarkan Department"
        value-expr="department_id"
        display-expr="department_name"
        :is-required="true"
        v-model="valueDepartment"
        :read-only="readOnlyDept"
        @valueChanged="changeDepartment"
      >
      </DxSelectBox>

      <DxSelectBox
        class="custom-form"
        v-if="disabledForm"
        :data-source="docNumberOptions"
        label="Pilih Nomor Dokumen"
        value-expr="id"
        display-expr="document_number"
        :accept-custom-value="true"
        :search-enabled="true"
        search-expr="document_number"
        item-template="customItem"
        @valueChanged="changeDocNumber"
        :read-only="readOnlyDocNum"
        v-model="valueDocNumber"
      >
        <template #customItem="{ data: itemData }">
          <div>
            <div>
              {{ itemData.document_number }}
              <b>| {{ itemData.document_name }}</b>
            </div>
          </div>
        </template>
      </DxSelectBox>

      <div>
        <DxTextBox
          v-if="docNumberOnly"
          class="custom-form"
          label="Nomor Dokumen"
          v-model:value="docNumber"
          :read-only="disabledForm"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxSelectBox
          class="custom-form"
          :data-source="typeDocumentOptions"
          label="Pilih Tipe Dokumen"
          value-expr="name"
          display-expr="name"
          v-model="requestDocument.doc_type"
          :is-required="true"
          :read-only="disabledForm"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTagBox
          v-if="disabledForm"
          class="custom-form"
          :search-enabled="true"
          :data-source="allDepartment"
          label="Department Relevan"
          display-expr="department_name"
          value-expr="department_id"
          :value="valueRelated"
          @value-changed="onValueChanged"
          :show-selection-controls="true"
          :maxFilterQueryLength="9999"
          item-template="item"
          :read-only="disabledForm"
        >
          <template #item="{ data: item }">
            <div class="custom-item">
              <div class="product-name">
                {{ item.department_name }} <b>|| {{ item.company_name }}</b>
              </div>
            </div>
          </template>
        </DxTagBox>

        <DxTextBox
          class="custom-form"
          label="Nama Dokumen"
          v-model:value="requestDocument.doc_name"
          :read-only="disabledForm"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
      </div>

      <DxTextArea
        class="custom-form"
        label="Tujuan"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="requestDocument.purpose"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>

      <div class="custom-form" v-if="disabledRevision">
        <div class="label-radio">Poin Poin Revisi</div>
        <DxHtmlEditor v-model:value="requestDocument.revision_points">
          <DxToolbar>
            <DxItem name="undo" />
            <DxItem name="redo" />
            <DxItem name="separator" />
            <DxItem
              name="header"
              :accepted-values="headerValues"
              :options="headerOptions"
            />
            <DxItem name="separator" />
            <DxItem name="bold" />
            <DxItem name="italic" />
            <DxItem name="strike" />
            <DxItem name="underline" />
            <DxItem name="separator" />
            <DxItem name="orderedList" />
            <DxItem name="bulletList" />
          </DxToolbar>

          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxHtmlEditor>
      </div>

      <div class="fileuploader-container custom-form" v-if="disabledFile">
        <DxFileUploader
          select-button-text="Pilih Dokumen"
          label-text="upload file"
          :allowed-file-extensions="['.docx', '.doc', '.pdf', '.xlsx']"
          :max-file-size="3000000"
          upload-mode="useForm"
          @value-changed="getFile"
        >
          <!-- <DxValidator v-if="this.getDocumentFileStatus == 'Add'">
            <DxRequiredRule message="Required" />
          </DxValidator> -->
        </DxFileUploader>
      </div>
      <div style="text-align: right; margin-bottom: 10px" v-if="disabledFile">
        Maximum file size:
        <span>3 MB</span>.
      </div>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
    </form>
  </div>
</template>
    
    <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxTagBox from "devextreme-vue/tag-box";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import notify from "devextreme/ui/notify";

import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      docNumberOptions: "",
      requestReasonOptions: [
        {
          name: "Buat Dokumen Baru",
        },
        {
          name: "Perubahan Dokumen Baru",
        },
        {
          name: "Permintaan Dokumen",
        },
      ],
      typeDocumentOptions: [
        {
          name: "KETENTUAN PERUSAHAAN",
        },
        {
          name: "SOP",
        },
        {
          name: "WI",
        },
        {
          name: "FORM",
        },
      ],
      submitButtonOptions: {
        text: "Submit the Form",
        type: "success",
        icon: "save",
        useSubmitBehavior: true,
      },
      loading: false,
      requestDocument: {},
      unitBisnisOptions: "",
      departmentOptions: "",
      valueUnitBisnis: "",
      valueDepartment: "",
      readOnlyUnit: true,
      readOnlyDept: true,
      readOnlyDocNum: true,
      valueRelated: [],
      allDepartment: [],
      valueDocNumber: "",
      docNumber: "",
      reqReason: false,
      file: "",
      docNumberOnly: false,
      headerValues: [false, 1, 2, 3, 4, 5],
      headerOptions: { inputAttr: { "aria-label": "Header" } },
    };
  },
  mounted() {
    this.unitBisnis();
    this.getRelatedDepartments();
    if (this.getRequestDocumentStatus == "Edit") {
      this.requestDocument = this.getRequestDocument;
      this.requestDocument._method = "PUT";
      this.checkRelevan(this.getRequestDocument.related_document);
      this.docNumber = this.getRequestDocument.doc_number;
      this.docNumberOnly = true;
      this.reqReason = true;
    } else if (this.getRequestDocumentStatus == "Request") {
      this.requestDocument.document_id = this.getRequestDocument.id;
      this.requestDocument.document_type =
        this.getRequestDocument.document_type;
      this.requestDocument.request_reason =
        this.getRequestDocument.request_reason;
      this.requestDocument.doc_type = this.getRequestDocument.document_type;
      this.docNumber = this.getRequestDocument.document_number;
      this.requestDocument.doc_number = this.getRequestDocument.document_number;
      this.requestDocument.doc_name = this.getRequestDocument.document_name;
      // this.valueDocNumber = this.getRequestDocument.id;
      this.docNumberOnly = true;
      this.checkRelevan(this.getRequestDocument.related_department);
    }
  },
  computed: {
    disabledFile() {
      if (
        this.requestDocument.request_reason == "Buat Dokumen Baru" ||
        this.requestDocument.request_reason == "Perubahan Dokumen Baru"
      ) {
        return true;
      }
      return false;
    },
    disabledForm() {
      if (this.requestDocument.request_reason !== "Buat Dokumen Baru") {
        return true;
      }
      return false;
    },
    disabledRevision() {
      if (this.requestDocument.request_reason == "Perubahan Dokumen Baru") {
        return true;
      }
      return false;
    },
    ...mapGetters("requestDocument", [
      "getRequestDocument",
      "getRequestDocumentStatus",
    ]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  props: {
    paramsEdit: {
      type: Boolean,
    },
  },
  emits: ["get-request-document"],
  methods: {
    cellTemplate(container, options) {
      container.innerHTML = options.data.revision_note;
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async getRelatedDepartments() {
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        `?`
      );
      this.allDepartment = depart.data;
    },
    async getDocuments(paramText = "") {
      const text = `?limit=99999&all_doc_active=true&${paramText}`;
      const doc = await this.$store.dispatch(
        "document/getWithDocApproval",
        text
      );
      this.docNumberOptions = doc.data;
    },
    onValueChanged(e) {
      let related = "";
      related = e.value;
      this.requestDocument.related_document = related.join();
    },
    async changeReqReason(params) {
      if (params.value == "Perubahan Dokumen Baru") {
        this.getDocuments(`department=${this.getUserDetail.department_id}`);

        this.readOnlyDocNum = false;
        this.valueDocNumber = "";
        this.readOnlyUnit = true;
        this.readOnlyDept = true;
      }
      // else if (params.value == "Permintaan Dokumen") {
      //   this.getDocuments(`except_depart=${this.getUserDetail.department_id}`);
      //   this.valueDocNumber = "";
      // }
      else {
        this.readOnlyUnit = false;
        this.valueUnitBisnis = "";
        this.valueDepartment = "";
        this.docNumberOptions = [];
      }
    },
    async changeDepartment(params) {
      if (params.value == this.getUserDetail.department_id) {
        this.readOnlyDocNum = true;
        this.valueDocNumber = "";
        notify("Dokumen tersedia di menu Daftar Dokumen Saya", "warning", 4000);
        return;
      }
      this.getDocuments(`department=${params.value}`);

      this.readOnlyDocNum = false;
      this.valueDocNumber = "";
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.docNumberOnly = false;
      this.readOnlyDept = false;
      this.readOnlyDocNum = true;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async changeDocNumber() {
      const doc = await this.$store.dispatch("document/getDocument", {
        id: this.valueDocNumber,
      });
      this.requestDocument.document_id = doc.data.id;
      this.requestDocument.doc_type = doc.data.document_type;
      this.requestDocument.doc_name = doc.data.document_name;
      this.requestDocument.doc_number = doc.data.document_number;
      // check relevan department
      this.checkRelevan(doc.data.related_department);
    },
    checkRelevan(params) {
      if (params != null) {
        this.valueRelated = params.split(",");
      } else {
        this.valueRelated = [];
      }
    },
    async handleSubmit(e) {
      if (this.requestDocument.request_reason == "Buat Dokumen Baru") {
        this.requestDocument.doc_number = "-";
      }

      if (this.requestDocument.request_reason == "Permintaan Dokumen") {
        this.requestDocument.received_date = new Date();
        this.requestDocument.head_signed = new Date();
        this.requestDocument.head_signature = this.getUserDetail.full_name;
      }
      this.requestDocument.applicant_id = this.getUserDetail.user_id;
      this.requestDocument.department_id = this.getUserDetail.department_id;
      this.requestDocument.applicant_signature = this.getUserDetail.full_name;
      this.requestDocument.attachment = this.file;
      this.loading = true;

      this.$emit("get-request-document", this.requestDocument);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxTagBox,
    DxFileUploader,
    FilterTableUnitBisnis,
    DxHtmlEditor,
    DxToolbar,
    DxItem,
  },
};
</script>
    
    <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.label-radio {
  color: darkgray;
  display: block;
}
.label-radio:after {
  content: " : ";
}
</style>