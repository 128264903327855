<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addCodeCompany" @submit.prevent="handleSubmit">
        <DxTextBox
          class="custom-form"
          label="Judul"
          v-model:value="menu.text"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxTextBox
          class="custom-form"
          label="Icon"
          v-model:value="menu.icon"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxCheckBox
          text="Aktif"
          :value="all"
          class="custom-form"
          @valueChanged="checkbox"
        />
        <br />
        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
      </form>
    </div>
  </div>
</template>
          
          <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxCheckBox } from "devextreme-vue/check-box";

export default {
  data() {
    return {
      loading: false,
      //   unitBisnisOptions: [],
      menu: {},
      //   id: this.$route.params.id,
      readOnly: false,
      all: false,
    };
  },
  computed: {
    ...mapGetters("menu", ["getMenuList", "getMenuListStatus"]),
  },
  mounted() {
    // this.unitBisnis();
    // console.log(this.getMenuList);
    // console.log(this.getMenuListStatus);
    if (this.getMenuListStatus == "Edit") {
      this.all = this.getMenuList.is_active;
      this.menu = this.getMenuList;
    }
  },
  props: {
    // edit: {
    //   type: Boolean,
    // },
  },
  emits: ["get-menu"],
  methods: {
    checkbox(params) {
      this.menu.is_active = params.value;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", "?");
      this.unitBisnisOptions = units.data;
    },
    async handleSubmit(e) {
      this.$emit("get-menu", this.menu);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxCheckBox,
    FilterTableUnitBisnis,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>