<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditReport" @submit.prevent="handleSubmit">
        <div class="custom-form">
          <div class="label-radio">Jenis Temuan</div>
          <DxRadioGroup
            :items="FindingType"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.finding_type_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Faktor Temuan</div>
          <DxRadioGroup
            :items="factorFinding"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.factor_finding_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Standard Penyimpangan</div>
          <DxRadioGroup
            :items="aberrationStandart"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.aberration_standart_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Klasifikasi Kesalahan</div>
          <DxRadioGroup
            :items="faultClassification"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.fault_classification_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Tipe Kesalahan</div>
          <DxRadioGroup
            :items="faultType"
            value-expr="id"
            display-expr="name"
            v-model:value="auditReport.fault_type_id"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Risiko Temuan</div>
          <DxRadioGroup
            :items="riskFinding"
            v-model:value="auditReport.risk_finding"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <div class="custom-form">
          <div class="label-radio">Tipe Temuan</div>
          <DxRadioGroup
            :items="findingDetailType"
            v-model:value="auditReport.finding_detail_type"
            layout="horizontal"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxRadioGroup>
        </div>

        <DxSelectBox
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model="auditReport.company_id"
          v-if="disableFinding"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <DxSelectBox
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model="auditReport.department_id"
          @valueChanged="changeDepartment"
          :is-required="true"
          :read-only="readOnlyDept"
          v-if="disableFinding"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          v-if="!disableFinding"
          class="custom-form"
          label="Company"
          :read-only="true"
          v-model:value="dataEdit.company"
        >
        </DxTextBox>
        <DxTextBox
          v-if="!disableFinding"
          class="custom-form"
          label="Department"
          :read-only="true"
          v-model:value="dataEdit.department"
        >
        </DxTextBox>

        <div class="custom-form">
          <DxTagBox
            :search-enabled="true"
            :data-source="verificationItemsOptions"
            label="Pilih Catatan Hasil Verifikasi"
            display-expr="verification_desc"
            value-expr="id"
            v-model="valueFindings"
            :show-selection-controls="true"
            :maxFilterQueryLength="99999"
            item-template="item"
            :read-only="readOnlyFind"
          >
            <template #item="{ data: item }">
              <div class="custom-item">
                <div class="product-name">
                  {{ item.verification_desc }} || {{ item.verification_status }}
                </div>
              </div>
            </template>
            <DxValidator v-if="getAuditReportStatus.action == 'Add'">
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTagBox>
          <div
            style="text-align: right; margin-bottom: 10px"
            v-if="!disableFinding"
          >
            <a @click.prevent="resetFinding" href="#">Reset Temuan</a>
          </div>
        </div>

        <DxSelectBox
          class="custom-form"
          :data-source="auditPlanOptions"
          label="Pilih Agenda Audit"
          value-expr="id"
          display-expr="event_name"
          v-model="auditReport.audit_plan_id"
          :is-required="true"
          :read-only="readOnlyFind"
          item-template="customItem"
        >
          <template #customItem="{ data: itemData }">
            <div>
              <div>
                <b>Nama Agenda : </b>
                {{ itemData.event_name }}({{ itemData.audit_category }}) ||
                <b>Tanggal Audit : </b
                ><span
                  >{{ formatIsoDate(itemData.start_date) }} -
                  {{ formatIsoDate(itemData.end_date) }}</span
                >
              </div>
            </div>
          </template>
          <!-- <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator> -->
        </DxSelectBox>

        <DxTextArea
          class="custom-form"
          label="Bukti Objektif"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditReport.proof_objective"
        >
        </DxTextArea>

        <DxDateBox
          class="custom-form"
          label="Deadline"
          :input-attr="{ 'aria-label': 'Date' }"
          type="date"
          v-model:value="auditReport.deadline"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxDateBox>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
      <DxPopup
        :width="300"
        height="auto"
        :show-title="true"
        title="Konfirmasi"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        v-model:visible="popupResetFinding"
      >
        <template #content="{}">
          <div class="popup-property-details">
            <b>Apakah yakin reset temuan ?</b>
            <div class="justify-center">
              <DxButton
                class="customBtn"
                text="ya"
                icon="check"
                type="success"
                @click="confirmDestroy()"
              />
              <DxButton
                class="customBtn"
                text="batal"
                icon="close"
                type="danger"
                @click="popupResetFinding = false"
              />
            </div>
          </div>
        </template>
      </DxPopup>
    </div>
  </div>
</template>
                
          <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxDateBox from "devextreme-vue/date-box";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxTagBox from "devextreme-vue/tag-box";
import { DxHtmlEditor } from "devextreme-vue/html-editor";
import DxPopup from "devextreme-vue/popup";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      loadIndicatorVisible: false,
      auditReport: {},
      oldDeadline: null,
      tipeAuditOptions: [{ name: "Internal" }, { name: "Eksternal" }],
      methodOptions: [{ name: "Offline" }, { name: "Online" }],
      criteriaAuditOptions: [{ name: "QMS" }, { name: "HSE" }],
      auditPlanOptions: "",
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
      FindingType: [],
      factorFinding: [],
      aberrationStandart: [],
      faultClassification: [],
      faultType: [],
      riskFinding: ["Low", "Medium", "High"],
      findingDetailType: ["Berulang", "Tidak Berulang"],
      verificationItemsOptions: {},
      // unitBisnisOptions: "",
      // departmentOptions: "",
      // auditPlanOptions: [],
      readOnlyFind: true,
      valueFindings: [],
      dataEdit: {
        findings: "",
        company: "",
        department: "",
      },
      disableFinding: true,
      popupResetFinding: false,
    };
  },
  mounted() {
    if (this.getAuditReportStatus.action == "Edit") {
      this.auditReport = this.getAuditReport;
      this.oldDeadline = this.getAuditReport.deadline;
      const findings = this.getAuditReport.audit_group_finding;

      if (findings.length > 0) {
        this.disableFinding = false;
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == this.auditReport.department_id
        );
        this.dataEdit.department = departName.department_name;
        this.dataEdit.company = departName.company_name;

        this.getFinding(this.auditReport.department_id);
        this.readOnlyFind = true;

        findings.forEach((element) => {
          this.valueFindings.push(element.audit_finding_id);
        });
      }
    }

    this.unitBisnis();
    this.getItemAuditReport();
  },
  emits: ["get-audit-report"],
  computed: {
    ...mapGetters("auditReport", ["getAuditReport", "getAuditReportStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    formatIsoDate(isoDate) {
      if (isoDate) {
        const dateObject = new Date(isoDate);

        // Mendapatkan komponen tanggal
        const day = dateObject.getDate().toString().padStart(2, "0");
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Bulan dimulai dari 0
        const year = dateObject.getFullYear();

        // Mendapatkan jam dan menit
        const hours = dateObject.getHours().toString().padStart(2, "0");
        const minutes = dateObject.getMinutes().toString().padStart(2, "0");

        // Mengembalikan format tanggal jam:menit
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      }
    },
    async confirmDestroy() {
      const dataDelete = this.getAuditReport.audit_group_finding;
      if (dataDelete.length > 0) {
        dataDelete.forEach((element) => {
          this.$store
            .dispatch("auditReport/destroyAuditGroupFinding", element)
            .then((res) => {})
            .catch((e) => {
              notify(e.message, "error", 2000);
            });
        });
        this.auditReport.audit_group_finding = [];
        this.valueFindings = "";
        this.auditReport.company_id = null;
        this.auditReport.department_id = null;
        this.disableFinding = true;
        notify("delete success", "success", 2000);
      }
      this.popupResetFinding = false;
    },
    resetFinding() {
      this.popupResetFinding = true;
    },
    async getFinding(params) {
      const text = `?limit=9999&department=${params}&except_status=OK&status=false`;
      const getFinding = await this.$store.dispatch(
        "auditFinding/getAuditFinding",
        text
      );
      this.verificationItemsOptions = getFinding.data;
    },
    async changeDepartment(params) {
      if (params.value) {
        this.readOnlyFind = false;
        this.valueFindings = "";
        this.getFinding(params.value);

        const text = `?limit=9999&department=${params.value}&agenda_type=Agenda Audit&status=true`;
        const getAuditPlan = await this.$store.dispatch(
          "auditPlan/getAuditPlan",
          text
        );
        this.auditPlanOptions = getAuditPlan.data;
      }
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      // if (this.getAuditReportStatus.action == "Add") {
      this.readOnlyFind = true;
      this.valueFindings = "";
      this.auditReport.department_id = null;
      // }

      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    async getAuditVerification(params) {
      const verification = await this.$store.dispatch(
        "auditVerificationItem/getAuditVerificationItem",
        `/${params}`
      );
      this.auditReport.finding_detail = verification.data.verification_desc;
    },
    async getChecklist(params) {
      const checklist = await this.$store.dispatch(
        "auditChecklist/getAuditChecklist",
        `/${params}`
      );
      this.auditReport.reference = checklist.data.clausul_relevan;
    },
    async getItemAuditReport() {
      const getFindingType = await this.$store.dispatch(
        "findingType/getFindingType",
        `?limit=999`
      );
      this.FindingType = getFindingType.data;

      const getFactorFinding = await this.$store.dispatch(
        "factorFinding/getFactorFinding",
        `?limit=999`
      );
      this.factorFinding = getFactorFinding.data;

      const getAberrationStandart = await this.$store.dispatch(
        "aberrationStandart/getAberrationStandart",
        `?limit=999`
      );
      this.aberrationStandart = getAberrationStandart.data;

      const getFaultClassification = await this.$store.dispatch(
        "faultClassification/getFaultClassification",
        `?limit=999`
      );
      this.faultClassification = getFaultClassification.data;

      const getFaultType = await this.$store.dispatch(
        "faultType/getFaultType",
        `?limit=999`
      );
      this.faultType = getFaultType.data;
    },
    handleSubmit(e) {
      this.loading = true;

      if (this.disableFinding) {
        let getfinding = this.verificationItemsOptions.filter((item) =>
          this.valueFindings.includes(item.id)
        );

        this.auditReport.findings = getfinding;
      }

      this.$emit("get-audit-report", this.auditReport);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    DxDateBox,
    DxRadioGroup,
    DxTagBox,
    DxHtmlEditor,
    DxPopup,
  },
};
</script>
          
          <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.center {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}

.label-radio {
  color: darkgray;
  display: block;
}
.label-radio:after {
  content: " : ";
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.dx-list-item-content {
  white-space: normal;
}
</style>