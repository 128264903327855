<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/auditImplementation" @submit.prevent="handleSubmit">
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file"
            :allowed-file-extensions="fileAllowed"
            :max-file-size="6000000"
            upload-mode="useForm"
            @value-changed="getFile"
          >
            <!-- <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator> -->
          </DxFileUploader>
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          Maximum file size:
          <span>5 MB</span>.
        </div>

        <DxSelectBox
          class="custom-form"
          :data-source="tipeAuditImplOptions"
          label="Pilih Tipe Bukti"
          value-expr="value"
          display-expr="name"
          :is-required="true"
          v-model="auditImplementation.proof_type"
        >
          <DxValidator> <DxRequiredRule message="Required" /> </DxValidator
        ></DxSelectBox>
        <DxTextArea
          class="custom-form"
          label="Keterangan"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditImplementation.desc"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
    <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditImplementation: {},
      tipeAuditImplOptions: [
        { value: "Before", name: "Kondisi Sebelumnya (Before)" },
        { value: "After", name: "Kondisi Sesudah (After)" },
      ],
      readOnly: false,
      file: "",
      fileAllowed: [".png", ".jpg", ".jpeg", ".docx", ".doc", ".pdf", ".xlsx"],
    };
  },
  computed: {
    ...mapGetters("auditImplementation", [
      "getAuditImplementation",
      "getAuditImplementationStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
  },
  mounted() {
    if (this.getAuditImplementationStatus.action == "Edit") {
      this.auditImplementation = this.getAuditImplementation;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-audit-impl"],
  methods: {
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];
      }
    },
    async handleSubmit(e) {
      this.loading = true;
      this.auditImplementation.proof_file = this.file;
      if (this.getAuditImplementationStatus.action == "Add") {
        this.auditImplementation.report_id = this.getAuditReport.id;
      }

      this.$emit("get-audit-impl", this.auditImplementation);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxFileUploader,
    DxLoadIndicator,
  },
};
</script>
              
              <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
</style>