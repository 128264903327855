<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/auditCorrection" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Perbaikan Permanen"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditCorrection.action"
        >
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      auditCorrection: {},
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters("auditCorrection", [
      "getAuditCorrection",
      "getAuditCorrectionStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
  },
  mounted() {
    if (this.getAuditCorrectionStatus.action == "Edit") {
      this.auditCorrection = this.getAuditCorrection;
    }
  },
  props: {
    edit: {
      type: Boolean,
    },
  },
  emits: ["get-audit-correction"],
  methods: {
    async handleSubmit(e) {
      this.loading = true;
      if (this.getAuditCorrectionStatus.action == "Add") {
        this.auditCorrection.report_id = this.getAuditReport.id;
      }
      this.$emit("get-audit-correction", this.auditCorrection);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>