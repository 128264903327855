<template>
  <div>
    <h2 class="content-block">Profile</h2>

    <div class="content-block dx-card responsive-paddings">
      <div class="form-avatar">
        <img src="../../public/user-blue.jpg" />
      </div>
      <h5>Nama : {{ getUserDetail.full_name || "-" }}</h5>
      <h5>Nip : {{ getUserDetail.nip || "-" }}</h5>
      <h5>Email : {{ getUserDetail.email || "-" }}</h5>
      <h5>Jabatan : {{ getUserDetail.jabatan_name || "-" }}</h5>
      <h5>Unit Bisnis : {{ getUserDetail.company_name || "-" }}</h5>
      <h5>Department : {{ getUserDetail.department_name || "-" }}</h5>
      <h5>Section : {{ getUserDetail.section_name || "-" }}</h5>
      <h5>Subsection : {{ getUserDetail.sub_section_name || "-" }}</h5>
      <h5>Kode Cabang : {{ getUserDetail.kode_cbg || "-" }}</h5>
      <div v-if="btnEdit">
        <DxButton
          text="Edit Account"
          icon="user"
          type="default"
          @click="clickEdit(true)"
        />
      </div>
    </div>
    <div v-if="formEdit">
      <h2 class="content-block">Edit Account</h2>
      <div class="content-block dx-card responsive-paddings">
        <form action="/addAuditChecklist" @submit.prevent="handleSubmit">
          <DxTextBox
            class="custom-form"
            label="Username"
            v-model:value="account.username"
            :is-required="true"
            ><DxValidator>
              <DxRequiredRule message="Required" />
              <DxStringLengthRule
                :min="5"
                message="The password must be at least 5 characters"
              />
            </DxValidator>
          </DxTextBox>
          <DxTextBox
            class="custom-form"
            label="Password Baru"
            v-model:value="account.password"
            :is-required="true"
            :mode="passwordMode"
            ><DxValidator>
              <DxRequiredRule message="Required" />
              <DxStringLengthRule
                :min="8"
                message="The password must be at least 8 characters"
              />
            </DxValidator>
          </DxTextBox>
          <DxButton
            class="customBtn"
            type="default"
            :icon="isVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
            @click="togglePasswordVisibility"
          >
          </DxButton>
          <DxButton
            class="customBtn"
            v-if="!loading"
            text="Simpan"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
          <DxButton
            class="customBtn"
            text="Batal"
            type="danger"
            icon="close"
            @click="clickEdit(false)"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DxForm from "devextreme-vue/form";
import { mapGetters, mapActions } from "vuex";
import DxButton from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";
import { DxTextBox } from "devextreme-vue/text-box";
import DxValidator, {
  DxRequiredRule,
  DxStringLengthRule,
  DxCustomRule,
} from "devextreme-vue/validator";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxForm,
    DxButton,
    DxPopup,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxStringLengthRule,
    DxCustomRule,
  },
  data() {
    return {
      formEdit: false,
      account: {},
      accountLogin: {},
      loading: false,
      btnEdit: true,
      passwordMode: "password",
      isVisible: true,
    };
  },
  props: {
    picture: { String },
  },
  computed: {
    ...mapGetters("authentication", ["getUserDetail", "getUser"]),
    close() {},
  },
  mounted() {},
  methods: {
    togglePasswordVisibility() {
      this.isVisible = !this.isVisible;
      this.passwordMode =
        this.passwordMode === "password" ? "text" : "password";
    },
    clickEdit(params) {
      if (params) {
        this.getUserLogin();
        this.formEdit = true;
        this.btnEdit = false;
      } else {
        this.formEdit = false;
        this.btnEdit = true;
        this.account = {};
      }
    },
    async getUserLogin() {
      const userLogin = await this.$store.dispatch(
        "authentication/getAccounts",
        `/check/${this.getUserDetail.user_id}`
      );
      this.account.id = userLogin.data.id;
      this.account.username = userLogin.data.username;
    },
    handleSubmit() {
      this.$store
        .dispatch("authentication/updateAccounts", this.account)
        .then((res) => {
          this.formEdit = false;
          this.btnEdit = true;
          this.account = {};
          notify("Silahkan logout untuk reset account", "success", 3000);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.username) {
            notify("Username sudah digunakan", "error", 3000);
            return;
          }
          notify(e.message, "error", 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>
