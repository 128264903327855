<template>
  <div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <!-- <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton text="edit" hint="edit" icon="edit" @click="edit" />
      </dx-column> -->
      <dx-column data-field="text" caption="Judul" />
      <!-- <dx-column data-field="icon" caption="Icon" /> -->
      <!-- <dx-column data-field="roles" caption="Level" :customize-text="roles" /> -->
      <dx-column data-field="path" caption="Link" />
    </dx-data-grid>
  </div>
</template>
    <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  // DxPopup,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import DxPopup from "devextreme-vue/popup";
import router from "@/router";
import tableDepartmentCode from "@/components/master/tableDepartmentCode.vue";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    tableDepartmentCode,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            code_menu: vthis.menuList.code,
            // search: filterValue,
            // company: vthis.unitBisnis,
            // department: vthis.department,
            // parentId: "null",
          });
          //   console.log(await datax);

          return await datax;
        },
      }),
    };
    return {
      gridRef: "menu-item-table",
      paramsEdit: "",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      btnAdd: null,
      popupAdd: false,
    };
  },
  mounted() {
    // console.log(this.manage);
    // this.fetch();
  },
  props: ["menuList"],
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
  },
  methods: {
    roles(cellInfo) {
      const data = cellInfo.value;
      if (data == 1) {
        return "User";
      } else if (data == 2) {
        return "Head";
      } else if (data == 3) {
        return "Admin";
      } else if (data == 4) {
        return "Super Admin";
      } else {
        return "All";
      }
    },
    edit(params) {
      this.$store.dispatch("unitBisnis/setCodeUnitStatus", "Edit");
      this.$store.dispatch("unitBisnis/setCodeUnit", params.row.data);
      router.push(`/admin/code/company/add`);
    },
    onToolbarPreparing(e) {
      // console.log(e);
      //   const vThis = this;
      //   e.toolbarOptions.items.unshift({
      //     location: "before",
      //     widget: "dxButton",
      //     options: {
      //       icon: "add",
      //       text: "Department",
      //       stylingMode: "outlined",
      //       type: "default",
      //       onInitialized: function (e) {
      //         this.btnAdd = e.component;
      //       },
      //       onClick: function () {
      //         vThis.$store.dispatch("department/setCodeDepartmentStatus", "Add");
      //         router.push(`/admin/code/department/add`);
      //       },
      //     },
      //   });
      //   e.toolbarOptions.items.unshift({
      //     location: "before",
      //     widget: "dxButton",
      //     options: {
      //       icon: "add",
      //       text: "Unit Bisnis",
      //       stylingMode: "outlined",
      //       type: "default",
      //       onInitialized: function (e) {
      //         this.btnAdd = e.component;
      //       },
      //       onClick: function () {
      //         vThis.$store.dispatch("unitBisnis/setCodeUnitStatus", "Add");
      //         router.push(`/admin/code/company/add`);
      //       },
      //     },
      //   });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "menu/getMenuItems",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
    <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  margin: 2%;
}
.table-center {
  width: 50%;
  margin: auto;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
</style>