<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/formAuditPlan" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="agendaTypeOptions"
          label="Pilih Tipe Agenda"
          value-expr="name"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeAgendaType"
          v-model="valueAgendaType"
          :read-only="readOnlyAgendaType"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          class="custom-form"
          label="Nama Agenda"
          v-model:value="auditPlan.event_name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxCheckBox
          v-if="!disableAgenda"
          text="All Unit & Department"
          :value="allDepartment"
          class="custom-form"
          @valueChanged="checkboxDepartment"
        />

        <DxTagBox
          v-if="validateTag"
          class="custom-form"
          :search-enabled="true"
          :data-source="allDepartmentOption"
          label="Pilih Department"
          display-expr="department_name"
          value-expr="department_id"
          :value="valueAllDepart"
          @value-changed="onValueChanged"
          :maxFilterQueryLength="9999"
          item-template="item"
          :read-only="disabledTag"
        >
          <template #item="{ data: item }">
            <div class="custom-item">
              <div class="product-name">
                {{ item.department_name }} <b>|| {{ item.company_name }}</b>
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTagBox>

        <!--  -->
        <!-- <div v-if="disableAgenda && getAuditPlanStatus.action == 'Edit'"> -->
        <DxSelectBox
          v-if="disableAgenda"
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model:value="auditPlan.company_id"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <DxSelectBox
          v-if="disableAgenda"
          class="custom-form"
          :data-source="departmentOptions"
          label="Pilih Department"
          value-expr="department_id"
          display-expr="department_name"
          v-model:value="auditPlan.department_id"
          :is-required="true"
          :read-only="readOnlyDept"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          v-if="
            disableAgenda && getAuditPlanStatus.action == 'Edit' && readOnlyDept
          "
          class="custom-form"
          label="department"
          v-model:value="nameDepart"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <!-- </div> -->

        <DxTextBox
          v-if="disableAgenda"
          class="custom-form"
          label="Partisipasi"
          v-model:value="auditPlan.participant"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <div class="input-group">
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="tipeAuditOptions"
            label="Pilih Tipe Audit"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_type"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxSelectBox
            class="group"
            :data-source="methodOptions"
            label="Pilih Methode"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.method"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="criteriaAuditOptions"
            label="Pilih Kriteria Audit"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_criteria"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>

        <div class="input-group">
          <DxDateBox
            class="group"
            label="Tanggal Mulai"
            :input-attr="{ 'aria-label': 'Date' }"
            type="datetime"
            v-model:value="auditPlan.start_date"
            display-format="d / MM / yyyy HH:mm"
            :show-clear-button="true"
            :use-mask-behavior="true"
          >
            <!-- display-format="d / MM / yyyy" -->
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxDateBox>
          <DxDateBox
            class="group"
            label="Tanggal Berakhir"
            :input-attr="{ 'aria-label': 'Date' }"
            type="datetime"
            v-model:value="auditPlan.end_date"
            display-format="d / MM / yyyy HH:mm"
            :show-clear-button="true"
            :use-mask-behavior="true"
          >
            <!-- display-format="d / MM / yyyy" -->
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxDateBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="categoryAuditOptions"
            label="Pilih Kategori Audit"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_category"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxDateBox from "devextreme-vue/date-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTagBox from "devextreme-vue/tag-box";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      loadIndicatorVisible: false,
      auditPlan: {},
      tipeAuditOptions: [{ name: "Internal" }, { name: "Eksternal" }],
      methodOptions: [{ name: "Offline" }, { name: "Online" }],
      criteriaAuditOptions: [{ name: "QMS" }, { name: "HSE" }],
      categoryAuditOptions: [{ name: "Terbuka" }, { name: "Tertutup" }],
      unitBisnisOptions: "",
      departmentOptions: "",
      agendaTypeOptions: [{ name: "Agenda Audit" }, { name: "Agenda Meeting" }],
      valueAgendaType: "",
      readOnlyAgendaType: false,
      readOnlyDept: true,
      allDepartment: true,
      allDepartmentOption: [],
      valueAllDepart: [],
      disabledTag: true,
      loadingTag: true,
      valueDepartment: "",
      nameDepart: "",
    };
  },
  emits: ["get-audit-plan"],
  computed: {
    ...mapGetters("auditPlan", ["getAuditPlan", "getAuditPlanStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    disableAgenda() {
      if (this.valueAgendaType != "Agenda Audit") {
        return false;
      }
      return true;
    },
    validateTag() {
      if (!this.disabledTag) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.getAuditPlanStatus.action == "Edit") {
      this.auditPlan = this.getAuditPlan;
      this.valueAgendaType = this.getAuditPlan.agenda_type;
      this.readOnlyAgendaType = true;
      this.nameDepart = this.getAuditPlan.department_name;
      this.allDepartment = this.getAuditPlan.all;

      const array = this.getAuditPlan.meeting_participants;
      this.valueAllDepart = array
        ? array.split(",").map((item) => item.trim())
        : [];
    }
    this.unitBisnis();
    this.getAllDepartment();
  },
  methods: {
    async getAllDepartment() {
      const departments = await this.$store.dispatch(
        "department/getDepartments",
        `?`
      );
      this.allDepartmentOption = departments.data;
    },
    onValueChanged(e) {
      let related = "";
      related = e.value;
      this.auditPlan.meeting_participants = related.join();
    },
    checkboxDepartment(params) {
      this.disabledTag = params.value;
      this.allDepartment = params.value;

      if (params.value == true) {
        this.valueAllDepart = [];
        this.auditPlan.meeting_participants = "";
      }
    },
    changeAgendaType(params) {
      this.auditPlan.agenda_type = params.value;
      if (params.value == "Agenda Audit") {
        this.auditPlan.status = false;
      }
    },
    async changeUnitBisnis(params) {
      const text = `?company=${params.value}`;
      const depart = await this.$store.dispatch(
        "department/getDepartments",
        text
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart.data;
    },
    async unitBisnis() {
      const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
      this.unitBisnisOptions = units.data;
    },
    handleSubmit(e) {
      this.loading = true;
      this.auditPlan.initiated_by = this.getUserDetail.id;
      if (this.auditPlan.agenda_type == "Agenda Meeting") {
        this.auditPlan.all = this.allDepartment;
      }

      this.$emit("get-audit-plan", this.auditPlan);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    DxDateBox,
    DxCheckBox,
    DxTagBox,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.center {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>