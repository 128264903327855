<template>
  <h2 class="content-block">Audit Checklist {{ title }}</h2>
  <filter-table-unit-bisnis
    @get-unit-bisnis="getCompany"
    @get-department="getDepartment"
  ></filter-table-unit-bisnis>

  <br />

  <DxTreeList
    id="tasks"
    ref="treeList"
    :data-source="dataSource"
    key-expr="id"
    parent-id-expr="parent_id"
    :show-borders="true"
    class="dx-card wide-card"
    :show-row-lines="false"
    :column-auto-width="true"
    :word-wrap-enabled="true"
    :expanded-row-keys="expandedRowKeys"
    :row-alternation-enabled="true"
  >
    <DxScrolling mode="standard" />
    <!-- <DxPaging :enabled="true" :page-size="10" />
    <DxPager
      :allowed-page-sizes="allowedPageSizes"
      :show-info="true"
      :visible="true"
      :showNavigationButtons="true"
      :show-page-size-selector="true"
    /> -->
    <DxPager
      :allowed-page-sizes="[5, 10]"
      :show-page-size-selector="true"
      :show-navigation-buttons="true"
      :visible="true"
    />
    <DxPaging :enabled="true" :page-size="10" />

    <DxColumn caption="Audit Checklist">
      <DxColumn
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <DxColumn data-field="clausul_relevan" caption="Clausul Relevan" />
    </DxColumn>
    <DxColumn data-field="process_name" caption="Proses" />
    <DxColumn data-field="activity" caption="Lingkup" />
    <DxColumn data-field="pic" caption="PIC" />
    <DxColumn caption="Potensi">
      <DxColumn data-field="potential" caption="Potensi Risiko" />
      <DxColumn data-field="risk_level" caption="Tingkat Risiko" />
    </DxColumn>
    <DxColumn data-field="risk_control" caption="Pengendali Risiko" />
    <DxColumn data-field="verification_items" caption="Item Verifikasi" />
  </DxTreeList>
</template>

<script>
import {
  DxTreeList,
  DxColumn,
  DxPaging,
  DxPager,
  DxScrolling,
} from "devextreme-vue/tree-list";
import DataSource from "devextreme/data/data_source";
import { dxItem } from "devextreme/ui/widget/ui.widget";
import { mapGetters, mapActions } from "vuex";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxPager,
    DxScrolling,
    FilterTableUnitBisnis,
  },
  data() {
    return {
      dataSource: new DataSource({
        load: this.loadData,
        // Kamu bisa menambahkan lebih banyak konfigurasi di sini jika perlu
      }),
      allowedPageSizes: [5, 10, 20],
      treeList: [],
      array: [],
      expandedRowKeys: [],
      company: "",
      department: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    getCompany(value) {
      this.company = value;
      this.$refs.treeList.instance.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.$refs.treeList.instance.refresh();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    refreshTreeList() {
      // Access the TreeList component instance and call refresh
      this.$refs.treeList.instance.refresh();
    },
    async loadData(options) {
      // console.log(options);

      // Membuat URL untuk memuat data dari server
      // const url = `http://127.0.0.1:8000/api/audit-checklists`;
      let text = `?company=${this.company}&department=${this.department}`;
      console.log(text);

      const getChecklist = await this.$store.dispatch(
        "auditChecklist/getAuditChecklist",
        text
      );
      this.array = getChecklist.data;
      if (getChecklist.paginate.total > 0) {
        this.expandedRowKeys.push(getChecklist.data[0].id);

        const getProcess = await this.$store.dispatch(
          "processList/getProcessList",
          ""
        );
        const process = getProcess.data;
        process.forEach((item) => {
          this.array.push(item);
        });

        const getScopeActivities = await this.$store.dispatch(
          "scopeActivities/getScopeActivities",
          ""
        );
        const activities = getScopeActivities.data;
        activities.forEach((item) => {
          this.array.push(item);
        });

        const getPicChecklist = await this.$store.dispatch(
          "picChecklist/getPicChecklist",
          ""
        );
        const pic = getPicChecklist.data;
        pic.forEach((item) => {
          this.array.push(item);
        });

        const getPotentialRisk = await this.$store.dispatch(
          "potentialRisk/getPotentialRisk",
          ""
        );
        const potentialRisk = getPotentialRisk.data;
        potentialRisk.forEach((item) => {
          this.array.push(item);
        });

        const getAuditRiskControl = await this.$store.dispatch(
          "auditRiskControl/getAuditRiskControl",
          ""
        );
        const auditRiskControl = getAuditRiskControl.data;
        auditRiskControl.forEach((item) => {
          this.array.push(item);
        });

        const getAuditVerificationItem = await this.$store.dispatch(
          "auditVerificationItem/getAuditVerificationItem",
          ""
        );
        const auditVerificationItem = getAuditVerificationItem.data;
        auditVerificationItem.forEach((item) => {
          this.array.push(item);
        });
      }

      try {
        // console.log(this.array);

        // checklist.forEach((item) => {
        //   this.array.push(item);
        //   const auditProcess = item.audit_process;
        //   auditProcess.forEach((itemProcess) => {
        //     this.array.push({
        //       id: itemProcess.id,
        //       parent_id: itemProcess.checklist_id,
        //       process_name: itemProcess.name,
        //     });
        //     const activities = itemProcess.scope_activities;
        //     activities.forEach((itemActivities) => {
        //       this.array.push({
        //         id: itemActivities.id,
        //         parent_id: itemActivities.process_id,
        //         activity: itemActivities.activity,
        //       });
        //     });
        //   });
        //   // console.log(auditProcess);

        //   // array.push({
        //   //   id: item.audit_process[0].id,
        //   //   checklist_id: item.audit_process[0].checklist_id,
        //   //   process_name: item.audit_process[0].name,
        //   // });
        // });

        // array.push(
        //   checklist.map((item) => ({
        //     id: item.id,
        //     clausul_relevan: item.clausul_relevan,
        //   }))
        // );
        // array.push(
        //   checklist.map((item) => ({
        //     id: item.audit_process[0].id,
        //     process_name: item.audit_process[0].name,
        //   }))
        // );
        // console.log(array);

        // const getProcess = await this.$store.dispatch(
        //   "processList/getProcessList",
        //   ""
        // );
        // const processList = getProcess.data;
        // processList.forEach((item) => {
        //   array.push(item);
        // });
        // Mengembalikan data dan informasi pagination ke DataSource
        return {
          data: this.array,
          // total: getChecklist.paginate.total,
        };
      } catch (error) {
        console.error("Error fetching data:", error);
        return {
          data: [],
          totalCount: 0,
        };
      }
    },
  },
};
</script>

<!-- <style scoped>
#app {
  margin: 20px;
}
</style> -->