<template>
  <div>
    <DxTabPanel>
      <DxItem title="Analisa Akar Masalah" template="root-couse" />
      <template #root-couse>
        <tableAuditRootCouse
          :roles="roles"
          :history="history"
          :auditReport="auditReport"
        ></tableAuditRootCouse>
      </template>
      <DxItem
        title="Tindakan Perbaikan Sementara"
        template="audit-prevective"
      />
      <template #audit-prevective>
        <tabelAuditPrevective
          :roles="roles"
          :history="history"
          :auditReport="auditReport"
        ></tabelAuditPrevective>
      </template>
      <DxItem title="Tindakan Perbaikan Permanen" template="audit-correction" />
      <template #audit-correction>
        <tabelAuditCorrection
          :roles="roles"
          :history="history"
          :auditReport="auditReport"
        ></tabelAuditCorrection>
      </template>
      <DxItem title="Bukti Implementasi" template="audit-implementation" />
      <template #audit-implementation>
        <tabelAudiitImpl
          :roles="roles"
          :history="history"
          :auditReport="auditReport"
        ></tabelAudiitImpl>
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import tableAuditRootCouse from "@/components/audit/tabelAuditRootCouse.vue";
import tabelAuditPrevective from "@/components/audit/tabelAuditPrevective.vue";
import tabelAuditCorrection from "@/components/audit/tabelAuditCorrection.vue";
import tabelAudiitImpl from "@/components/audit/tabelAuditImplementation.vue";

export default {
  components: {
    DxTabPanel,
    DxItem,
    tableAuditRootCouse,
    tabelAuditPrevective,
    tabelAuditCorrection,
    tabelAudiitImpl,
  },
  data() {
    return {};
  },
  props: {
    roles: {
      type: String,
    },
    history: {
      type: Boolean,
    },
    auditReport: {
      type: Object,
    },
  },
};
</script>