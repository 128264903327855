<template>
  <div>
    <h2 class="content-block">Daftar Menu</h2>
    <tableMenu></tableMenu>
  </div>
</template>
        
  <script>
import tableMenu from "@/components/menu/tableMenu.vue";

export default {
  data() {
    return {};
  },
  mounted() {},
  props: {},
  methods: {},
  components: {
    tableMenu,
  },
};
</script>