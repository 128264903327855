<template>
  <div>
    <h2 class="content-block">
      <span v-if="history">Riwayat </span>Laporan Audit {{ title }}
    </h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="roles == 'admin' || roles == 'suAdmin'"
    ></filter-table-unit-bisnis>
    <br />
    <br />
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          v-if="!history && roles != 'user'"
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="fieldChooser"
        />
        <DxButton
          hint="view"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
        <DxButton
          v-if="!history && roles != 'user'"
          hint="verifikasi"
          icon="fa fa-check-to-slot"
          @click="verification"
        />"
        <DxButton
          hint="hapus"
          icon="trash"
          @click="popupDestroy"
          v-if="roles == 'suAdmin'"
        />
      </dx-column>
      <dx-column :width="150" data-field="report_number" caption="No Capa" />
      <dx-column
        data-field="department_id"
        caption="Department"
        :customize-text="departmentName"
      />
      <dx-column data-field="finding_type" caption="Jenis Temuan" />
      <dx-column data-field="finding_detail_type" caption="Detail Temuan" />
      <dx-column
        data-field="audit_group_finding"
        caption="Temuan"
        :cell-template="cellTemplate"
        width="400"
      />
      <dx-column
        data-field="proof_objective"
        caption="Bukti Objektif"
        cell-template="proof-objective"
        width="200"
      />
      <template #proof-objective="{ data }">
        <div class="wrap-text">
          {{ data.value }}
        </div>
      </template>
      <dx-column
        data-field="deadline"
        caption="Deadline"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="status"
        caption="Status"
        cell-template="grid-cell"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            close: data.value == 'Close',
            dikembalikan: data.value == 'Dikembalikan',
            pending: data.value == 'Pending',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column data-field="approved_by" caption="Diverifikasi Oleh" />
      <dx-column
        data-field="approved_date"
        caption="Tanggal Diverifikasi"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="audit_date"
        caption="Tanggal Dibuat"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="reason"
        caption="Keterangan"
        cell-template="reason"
        width="300"
      />
      <template #reason="{ data }">
        <div class="wrap-text">
          {{ data.value }}
        </div>
      </template>

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: auditReport }">
        <masterDetailAuditReport
          :roles="roles"
          :history="history"
          :auditReport="auditReport.data"
        ></masterDetailAuditReport>
      </template>
    </dx-data-grid>

    <!-- popup verification -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Verifikasi Laporan Audit"
      :drag-enabled="false"
      v-model:visible="popupVerif"
    >
      <template #content="{}">
        <b>Pastikan laporan audit sudah dicek!</b>
        <div class="popup-property-details">
          <div>
            <form
              action="/editVerificationReport"
              @submit.prevent="confirmVerif"
            >
              <DxSelectBox
                class="custom-form"
                :data-source="statusOptions"
                label="Pilih Status"
                v-model="verifData.status"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxSelectBox>
              <DxTextArea
                class="custom-form"
                label="Alasan"
                :height="90"
                :input-attr="{ 'aria-label': 'Notes' }"
                v-model:value="verifData.reason"
              >
                <DxValidator>
                  <DxRequiredRule message="Required" />
                </DxValidator>
              </DxTextArea>
              <div class="justify-center">
                <DxButtonx
                  class="customBtn"
                  text="Simpan"
                  icon="save"
                  type="success"
                  :use-submit-behavior="true"
                />
                <DxButtonx
                  class="customBtn"
                  text="Batal"
                  icon="close"
                  type="danger"
                  @click="cancle"
                />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup detail -->
    <DxPopup
      width="70%"
      height="80%"
      :show-title="true"
      title="Detail Laporan Audit"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-field">
                <div class="dx-field-label">Tanggal Laporan</div>
                <div class="dx-field-value">
                  : {{ detailData.audit_date.toString() }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Nomor Capa</div>
                <div class="dx-field-value">
                  : {{ detailData.report_number }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Jenis Temuan</div>
                <div class="dx-field-value">
                  : {{ detailData.finding_type }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Faktor Temuan</div>
                <div class="dx-field-value">
                  : {{ detailData.factor_finding }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Standard Penyimpangan</div>
                <div class="dx-field-value">
                  : {{ detailData.aberration_standart }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Klasifikasi Kesalahan</div>
                <div class="dx-field-value">
                  : {{ detailData.fault_classification }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Type Kesalahan</div>
                <div class="dx-field-value">: {{ detailData.fault_type }}</div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Tipe Risiko Temuan</div>
                <div class="dx-field-value">
                  : {{ detailData.risk_finding }}
                </div>
              </div>
              <hr />
              <div class="dx-field">
                <div class="dx-field-label">Tipe Detail Temuan</div>
                <div class="dx-field-value">
                  : {{ detailData.finding_detail_type }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Detail Temuan dan Refrensi</div>
                <div class="dx-field-value">
                  <DxList
                    :data-source="detailData.audit_group_finding"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <div>
                        <span>{{ index + 1 }}. </span
                        >{{ data.finding_desc }} ({{ data.refrensi }})
                      </div>
                    </template>
                  </DxList>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Bukti Objektif</div>
                <div class="dx-field-value">
                  : {{ detailData.proof_objective }}
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Deadline CAPA</div>
                <div class="dx-field-value">: {{ detailData.deadline }}</div>
              </div>

              <hr />

              <div class="dx-field">
                <div class="dx-field-label">
                  <b>Metode 5 Why</b>
                  <DxList
                    :data-source="auditRootCouse"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <p>
                        <span>{{ index + 1 }}. </span>{{ data.quesion }}
                      </p>
                    </template>
                  </DxList>
                </div>
                <div class="dx-field-value">
                  <b>Analisa Akar Masalah</b>
                  <DxList
                    :data-source="auditRootCouse"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <p>
                        <span>{{ index + 1 }}. </span>{{ data.answer }}
                      </p>
                    </template>
                  </DxList>
                </div>
              </div>

              <hr />

              <div class="dx-field">
                <div class="dx-field-label">Tindakan Perbaikan Sementara</div>
                <div class="dx-field-value">
                  <DxList
                    :data-source="auditPrevective"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <p>
                        <span>{{ index + 1 }}. </span>{{ data.action }}
                      </p>
                    </template>
                  </DxList>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label">Tindakan Perbaikan Permanen</div>
                <div class="dx-field-value">
                  <DxList
                    :data-source="auditCorrection"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <p>
                        <span>{{ index + 1 }}. </span>{{ data.action }}
                      </p>
                    </template>
                  </DxList>
                </div>
              </div>

              <hr />

              <div class="dx-field">
                <div class="dx-field-label">Kondisi Sebelum (Before)</div>
                <div class="dx-field-value">
                  <DxList
                    :data-source="auditImplBefore"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <div>{{ index + 1 }}. {{ data.desc }}</div>
                      <div class="img-center">
                        <img
                          :src="data.url_file"
                          :alt="`Picture of ${data.proof_file}`"
                          class="img-custom"
                        />
                      </div>
                    </template>
                  </DxList>
                  <hr />
                </div>
              </div>

              <div class="dx-field">
                <div class="dx-field-label">Kondisi Sesudah (After)</div>
                <div class="dx-field-value">
                  <DxList
                    :data-source="auditImplAfter"
                    item-template="list-item"
                  >
                    <template #list-item="{ data, index }">
                      <div>{{ index + 1 }}. {{ data.desc }}</div>
                      <div class="img-center">
                        <img
                          :src="data.url_file"
                          :alt="`Picture of ${data.proof_file}`"
                          class="img-custom"
                        />
                      </div>
                    </template>
                  </DxList>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
        <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxList, DxItem } from "devextreme-vue/list";
import tabelProcess from "@/components/audit/tabelProcess.vue";
import masterDetailAuditReport from "@/components/audit/masterDetailAuditReport.vue";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    DxScrollView,
    DxList,
    DxItem,
    tabelProcess,
    masterDetailAuditReport,
    FilterTableUnitBisnis,
    DxSelectBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            company: vthis.company || "",
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "audit-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupDetail: false,
      popupDelete: false,
      popupVerif: false,
      detailData: {},
      deleteData: {},
      verifData: {},
      auditRootCouse: [],
      auditPrevective: [],
      auditCorrection: [],
      auditImplBefore: [],
      auditImplAfter: [],
      company: "",
      department: "",
      title: "",
      statusOptions: ["Dikembalikan", "Close"],
    };
  },
  mounted() {},
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("auditPlan", ["getAuditPlan"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  props: {
    roles: {
      type: String,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmVerif() {
      this.verifData.approved_by = this.getUserDetail.full_name;
      this.verifData.approved_date = new Date();

      this.$store
        .dispatch("auditReport/editAuditReport", this.verifData)
        .then((res) => {
          notify("verifikasi success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupVerif = false;
    },
    cancle() {
      this.verifData = {};
      this.popupVerif = false;
    },
    verification(params) {
      this.popupVerif = true;

      this.verifData = params.row.data;
      this.verifData.status = "";
    },
    cellTemplate(container, options) {
      let value = options.value;
      let content = "";

      value.forEach((res, index) => {
        content += `<li style="
          margin: 0;
          padding: 2px 0;
          white-space: normal;
          overflow-wrap: break-word;
        ">${index + 1}. ${res.finding_desc} ( ${res.refrensi} )</li>`;
      });

      container.innerHTML = `<ul style="padding:0;margin:0">${content}</ul>`;
    },
    getCompany(value) {
      this.company = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    viewDetail(params) {
      this.popupDetail = true;

      this.detailData = params.row.data;
      this.getRelationReport(params.row.data.id);
    },
    async getRelationReport(params) {
      // get audit root couse
      const getRootCouse = await this.$store.dispatch(
        "auditRootCouse/getAuditRootCouse",
        `?limit=999&report=${params}`
      );
      this.auditRootCouse = getRootCouse.data;
      // get audit correction
      const getAuditCorrection = await this.$store.dispatch(
        "auditCorrection/getAuditCorrection",
        `?limit=999&report=${params}`
      );
      this.auditCorrection = getAuditCorrection.data;
      // get audit prevective
      const getAuditPrevective = await this.$store.dispatch(
        "auditPrevective/getAuditPrevective",
        `?limit=999&report=${params}`
      );
      this.auditPrevective = getAuditPrevective.data;
      // get audit impl before
      const getAuditImplBefore = await this.$store.dispatch(
        "auditImplementation/getAuditImplementation",
        `?limit=999&report=${params}&type=Before`
      );
      this.auditImplBefore = getAuditImplBefore.data;
      // get audit impl after
      const getAuditImplAfter = await this.$store.dispatch(
        "auditImplementation/getAuditImplementation",
        `?limit=999&report=${params}&type=After`
      );
      this.auditImplAfter = getAuditImplAfter.data;
    },
    dateColumn(cellInfo) {
      if (cellInfo.value == null) {
        return "-";
      }
      const date = new Date(cellInfo.value);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "-" + mm + "-" + date.getFullYear();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditChecklist/destroyAuditChecklist", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    fieldChooser(params) {
      this.$store.dispatch("auditReport/setAuditReportStatus", {
        action: "Edit",
        roles: this.roles,
      });

      this.$store.dispatch("auditReport/setAuditReport", params.row.data);
      router.push(`/audit-report/form`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.roles != "user" && !this.history) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.$store.dispatch("auditReport/setAuditReportStatus", {
                action: "Add",
                roles: vThis.roles,
              });
              router.push(`/audit-report/form`);
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.roles == "user" && !this.history) {
        params.department = this.getUserDetail.department_id || "-";
        this.title = this.getUserDetail.department_name || "-";
        params.except_status = "Close";
      } else if (this.roles == "user" && this.history) {
        params.department = this.getUserDetail.department_id || "-";
        this.title = this.getUserDetail.department_name || "-";
        params.status = "Close";
      } else if (this.history) {
        params.status = "Close";
      } else if (!this.history) {
        params.except_status = "Close";
      } else {
        console.log("error");
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditReport/getAuditReport",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
        <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}

.dx-list-item-content {
  white-space: normal;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.close {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.dikembalikan {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.pending {
  background-color: #ffff00;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 5px;
}
.array-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: normal;
  overflow-wrap: break-word;
}

.array-list li {
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.img-custom {
  width: 50%;
  height: auto;
}
.img-custom:hover {
  width: 100%;
  height: auto;
}
.wrap-text {
  white-space: normal;
  overflow-wrap: break-word;
}
.img-center {
  justify-content: center;
  display: flex;
}
</style>