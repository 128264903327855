import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";
import Cookie from 'js-cookie'
import { store } from './store'
import notify from "devextreme/ui/notify";

import Cookies from "js-cookie";
import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

import notFound from "./views/not-found.vue";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

// USER
// regulation
import ManageRegulationUser from "./views/user-pages/regulation/manageRegulation";
import ManageRegulationDetailUser from "./views/user-pages/regulation/manageRegulationDetail";
import RegulationListUser from "./views/user-pages/regulation/regulationList";
import GetRegulationDetailUser from "./views/user-pages/regulation/getRegulationDetail";
import FormRegulation from "./views/form-pages/regulation/regulationList.vue";
import FormRegulationDocument from "./views/form-pages/regulation/regulationDocument.vue";
import FormRegulationDetail from "./views/form-pages/regulation/regulationDetail.vue";
import FormRegulationImpl from "./views/form-pages/regulation/regulationImpl.vue";
// request document
import GetTemplateDocs from "./views/user-pages/document-management/getTemplateDocs";
import GetRequestDocument from "./views/user-pages/document-management/getRequestDocument";
import AddRequestDocument from "./views/user-pages/document-management/addRequestDocument";
import HistoryRequestDocument from "./views/user-pages/document-management/historyRequestDocument";
// document
import GetDocument from "./views/user-pages/document-management/getDocument";
import GetDetailDocument from "./views/user-pages/document-management/getDetailDocument";
import GetAllDocument from "./views/user-pages/document-management/getAllDocument";
import GetAllDetailDocument from "./views/user-pages/document-management/getAllDetailDocument";
import GetDocumentAccess from "./views/user-pages/document-management/getDocumentAccess";
import GetDocumentExpired from "./views/user-pages/document-management/getDocExpired.vue";

import GetForm from "./views/user-pages/document-management/getForm";
import GetWi from "./views/user-pages/document-management/getWi";
import GetSop from "./views/user-pages/document-management/getSop";
import GetKetentuan from "./views/user-pages/document-management/getKetentuan";

// Start Risk Management
// User
import GetRisk from "./views/user-pages/risk-management/getRisk";
import GetRiskIdentification from "./views/user-pages/risk-management/getRiskIdentification";
// Admin
import GetRiskAdmin from "@/views/admin-pages/admin/risk-management/getRisk"
import GetRiskIdentificationAdmin from "@/views/admin-pages/admin/risk-management/getRiskIdentification"
import GetRiskControlItemAdmin from "@/views/admin-pages/admin/risk-management/getRiskControlItem"
import GetRiskControlAdmin from "@/views/admin-pages/admin/risk-management/getRiskControl"
import GetRiskSeverityAdmin from "@/views/admin-pages/admin/risk-management/getRiskSeverity"
// Super Admin
import GetRiskSuAdmin from '@/views/admin-pages/super-admin/risk-management/getRisk'
import GetRiskIdentificationSuAdmin from '@/views/admin-pages/super-admin/risk-management/getRiskIdentification'
import GetRiskControlItemSuAdmin from '@/views/admin-pages/super-admin/risk-management/getRiskControlItem'
import GetRiskSeveritySuAdmin from '@/views/admin-pages/super-admin/risk-management/getRiskSeverity.vue'
import GetRiskControlSuAdmin from '@/views/admin-pages/super-admin/risk-management/getRiskControl.vue'
// Form Risk Management
import FormRisk from "./views/form-pages/risk-management/risk.vue";
import FormRiskIdentification from "./views/form-pages/risk-management/riskIdentification.vue"
import FormRiskScore from "./views/form-pages/risk-management/riskScore.vue";
import FormRiskImpPlan from "./views/form-pages/risk-management/riskImpPlan.vue";
import FormRiskControlDetail from "./views/form-pages/risk-management/riskControlDetail.vue";
import FormRiskControlItem from "@/views/form-pages/risk-management/riskControlItem.vue"
import FormRiskScoreIndicator from "@/views/form-pages/risk-management/riskScoreIndicator.vue"
import FormRiskControl from "@/views/form-pages/risk-management/riskControl.vue"
import FormRiskSeverity from "@/views/form-pages/risk-management/riskSeverity.vue"
// End
import GetHome from '@/views/home-page.vue'

// VERIFIKATOR
// request document
import GetRequestDocumentHead from "./views/admin-pages/verifier/document-management/getRequestDocument";
import HistoryRequestDocumentHead from "./views/admin-pages/verifier/document-management/historyRequestDocument";
// document
import GetVerifDocumentHead from "./views/admin-pages/verifier/document-management/getVerifDocument";
import GetVerifDetailDocumentHead from "./views/admin-pages/verifier/document-management/getVerifDetailDocument";

import GetDocumentHead from "./views/admin-pages/verifier/document-management/getDocument";
import GetDetailDocumentHead from "./views/admin-pages/verifier/document-management/getDetailDocument";

// ADMIN
// Document Management
import GetRequestDocumentAdmin from "./views/admin-pages/admin/document-management/getRequestDocument";
import HistoryRequestDocumentAdmin from "./views/admin-pages/admin/document-management/historyRequestDocument";

import GetDocFrmAdmin from "./views/admin-pages/admin/document-management/getDocFrm.vue";
import GetDocKepPerAdmin from "./views/admin-pages/admin/document-management/getDocKetentuan.vue";
import GetDocSopAdmin from "./views/admin-pages/admin/document-management/getDocSop.vue";
import GetDocWiAdmin from "./views/admin-pages/admin/document-management/getDocWi.vue";
import AddDocumentAdmin from "./views/admin-pages/admin/document-management/addDocument";
import DetailDocumentAdmin from "./views/admin-pages/admin/document-management/detailDocument";
import AddDocumentFileAdmin from "./views/admin-pages/admin/document-management/addDocumentFile";
import ViewPdf from "./components/partials/pdfView";
import GetDocExpired from "./views/admin-pages/admin/document-management/getDocExpired";

//Regulation
import GetRegulationListAdmin from "@/views/admin-pages/admin/regulation/getRegulationList"
import GetRegulationDetailAdmin from "@/views/admin-pages/admin/regulation/getRegulationDetail"
import GetRegulationDashAdmin from "@/views/admin-pages/admin/regulation/getRegulationDash"

//master
import AddUserAdmin from "@/views/admin-pages/admin/master/addUser"
import GetAccountActiveAdmin from "@/views/admin-pages/admin/master/getAccount"
import GetAccountVerifAdmin from "@/views/admin-pages/admin/master/getVerifAccount"
import GetAccountNotActiveAdmin from "@/views/admin-pages/admin/master/getAccountNotActive"
import GetCodeAdmin from "@/views/admin-pages/admin/master/getCode"
import AddCompanyCodeAdmin from "@/views/admin-pages/admin/master/addCompanyCode"
import AddDepartmentCodeAdmin from "@/views/admin-pages/admin/master/addDepartmentCode"
import GetTemplateDocsAdmin from "@/views/admin-pages/admin/master/getTemplateDocs"
import FormTemplateDocsAdmin from "@/views/admin-pages/admin/master/addTemplateDocs"

// risk management
// import FormRiskScoreAdmin from "@/views/admin-pages/admin/risk-management/editRiskScore"
// import FormRiskControlDetailAdmin from "@/views/admin-pages/admin/risk-management/editRiskControlDetail"
// import FormRiskImpPlanAdmin from "@/views/admin-pages/admin/risk-management/editRiskImpPlan"

// calibration
import GetAssetAdmin from "@/views/admin-pages/admin/calibration/getAsset"

// SUPER ADMIN
// import GetHomeSuAdmin from '@/views/admin-pages/super-admin/homePageSuAdmin'

import GetRegulationSuAdmin from '@/views/admin-pages/super-admin/regulation/getRegulation'
import GetRegulationDetailSuAdmin from '@/views/admin-pages/super-admin/regulation/getRegulationDetail'
import FormRegulationSuAdmin from '@/views/admin-pages/super-admin/regulation/addRegulationList'
import FormRegulationDocumentSuAdmin from '@/views/admin-pages/super-admin/regulation/addRegulationDocument'
import FormRegulationDetailSuAdmin from '@/views/admin-pages/super-admin/regulation/addRegulationDetail'
import FormRegulationImplSuAdmin from '@/views/admin-pages/super-admin/regulation/addRegulationImpl'

import GetReqDocSuAdmin from '@/views/admin-pages/super-admin/requestDocument/getReqDocument'
import GetHistoryReqDocSuAdmin from '@/views/admin-pages/super-admin/requestDocument/getHistoryReqDocument'
import FormReqDocSuAdmin from '@/views/admin-pages/super-admin/requestDocument/addReqDocument'
import GetDocumentSuAdmin from '@/views/admin-pages/super-admin/document/getDocument'
import FormDocumentSuAdmin from '@/views/admin-pages/super-admin/document/addDocument'
import GetDocumentDetailSuAdmin from '@/views/admin-pages/super-admin/document/getDocumentDetail'
import FormDocumentDetailSuAdmin from '@/views/admin-pages/super-admin/document/addDocumentFile'
import GetCodeSuAdmin from '@/views/admin-pages/super-admin/master/getCode'
import FormCodeCompanySuAdmin from '@/views/admin-pages/super-admin/master/addCompanyCode'
import FormCodeDepartmentSuAdmin from '@/views/admin-pages/super-admin/master/addDepartmentCode'
import GetTemplateDocsSuAdmin from '@/views/admin-pages/super-admin/master/getTemplateDoc'
import FormTemplateDocsSuAdmin from '@/views/admin-pages/super-admin/master/addTemplateDocs'
import AccountActiveSuAdmin from '@/views/admin-pages/super-admin/master/getAccount'
import GetAccountNotActiveSuAdmin from '@/views/admin-pages/super-admin/master/getAccountNotActive'
import GetAccountVerifSuAdmin from '@/views/admin-pages/super-admin/master/getVerifAccount'

import GetMenuSuAdmin from '@/views/admin-pages/super-admin/getMenu'
import FormMenuSuAdmin from '@/views/admin-pages/super-admin/formMenuList'

// Audit

import GetAuditPlanUser from '@/views/user-pages/audit/getAuditPlan'
// Admin
import GetAuditPlanAdmin from '@/views/admin-pages/admin/audit/getAuditPlan'
import FormAuditPlanAdmin from '@/views/form-pages/audit/auditPlan.vue'
import GetAuditChecklistAdmin from '@/views/admin-pages/admin/audit/getAuditChecklist.vue'
import FormAuditChecklistAdmin from '@/views/form-pages/audit/auditChecklist.vue'
import FormAuditProcessAdmin from '@/views/form-pages/audit/processList.vue'
import FormScopeActivitiesAdmin from '@/views/form-pages/audit/scopeActivities.vue'
import FormPicChecklistAdmin from '@/views/form-pages/audit/picChecklist.vue'
import FormPotentialRiskAdmin from '@/views/form-pages/audit/potentialRisk.vue'
import FormAuditRiskControlAdmin from '@/views/form-pages/audit/auditRiskControl.vue'
import FormAuditVerificationItemAdmin from '@/views/form-pages/audit/auditVerificationItem.vue'
import GetAuditReportAdmin from '@/views/admin-pages/admin/audit/getAuditReport.vue'
import getAuditHistoryReportAdmin from '@/views/admin-pages/admin/audit/getHistoryAuditReport.vue'
import FormAuditReportAdmin from '@/views/form-pages/audit/auditReport.vue'
import FormRootCouseAdmin from '@/views/form-pages/audit/auditRootCouse.vue'
import FormAuditCorrectionAdmin from '@/views/form-pages/audit/auditCorrection.vue'
import FormAuditPrevectiveAdmin from '@/views/form-pages/audit/auditPrevective.vue'
import FormAuditImplAdmin from '@/views/form-pages/audit/auditImpl.vue'
import GetAuditFindingAdmin from '@/views/admin-pages/admin/audit/getAuditFinding.vue'
import GetHistoryAuditFindingAdmin from '@/views/admin-pages/admin/audit/getHistoryAuditFinding.vue'
import FormAuditFindingAdmin from '@/views/form-pages/audit/auditFinding.vue'
import GetAuditReportUser from '@/views/user-pages/audit/getAuditReport.vue'
import GetAuditHistoryReportUser from '@/views/user-pages/audit/getAuditHistoryReport.vue'
import GetAuditFindingTypeAdmin from '@/views/admin-pages/admin/audit/getAuditFindingType.vue'
import FormAuditFindingType from '@/views/form-pages/audit/auditFindingType.vue'
import GetAuditFactorFindingAdmin from '@/views/admin-pages/admin/audit/getAuditFactorFinding.vue'
import FormAuditFactorFinding from '@/views/form-pages/audit/auditFactorFinding.vue'
import GetAuditAberrationStandartAdmin from '@/views/admin-pages/admin/audit/getAuditAberrationStandart.vue'
import FormAuditAberrationStandart from '@/views/form-pages/audit/auditAberrationStandart.vue'
import GetAuditfaultClassificationAdmin from '@/views/admin-pages/admin/audit/getAuditFaultClassification.vue'
import FormAuditfaultClassification from '@/views/form-pages/audit/auditFaultClassification.vue'
import GetAuditfaultTypeAdmin from '@/views/admin-pages/admin/audit/getAuditFaultType.vue'
import FormAuditfaultType from '@/views/form-pages/audit/auditFaultType.vue'
import GetCapaUser from '@/views/user-pages/capa/getCapa.vue'

import Getpivot from '@/views/admin-pages/admin/audit/getpivot.vue'
import SingleCard from "./layouts/single-card.vue";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    //USER
    //regulations
    {
      path: "/home",
      name: "getHome",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: GetHome
    },
    // {
    //   path: "/user/regulation/info",
    //   name: "getRegulationUser",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: RegulationListUser
    // },
    {
      path: "/user/regulation-detail",
      name: "getRegulationDetailUser",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: GetRegulationDetailUser
    },
    {
      path: "/regulation/form",
      name: "formRegulation",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRegulation
    },
    {
      path: "/regulation-document/form",
      name: "formRegulationDocument",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRegulationDocument
    },
    {
      path: "/regulation-detail/form",
      name: "formRegulationDetail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRegulationDetail
    },
    {
      path: "/regulation-impl/form",
      name: "formRegulationImpl",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRegulationImpl
    },
    // {
    //   path: "/user/regulation/manage",
    //   name: "manageRegulationUser",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: ManageRegulationUser
    // },
    {
      path: "/user/regulation-detail/manage",
      name: "manageRegulationDetailUser",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ManageRegulationDetailUser
    },


    // request dokumen
    // USER
    // {
    //   path: "/user/template-docs/info",
    //   name: "getTemplateDocs",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetTemplateDocs
    // },
    {
      path: "/user/request-document/add",
      name: "addRequestDocument",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AddRequestDocument
    },
    // {
    //   path: "/user/request-document/info",
    //   name: "getRequestDocument",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRequestDocument
    // },
    // {
    //   path: "/user/request-document/history",
    //   name: "historyRequestDocument",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: HistoryRequestDocument
    // },

    // Document Management

    // {
    //   path: "/user/all-document/sop",
    //   name: "getAllDocumentSop",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetSop
    // },
    // {
    //   path: "/user/all-document/ketentuan",
    //   name: "getAllDocumentKetentuan",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetKetentuan
    // },
    // {
    //   path: "/user/all-document/wi",
    //   name: "getAllDocumentWi",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetWi
    // },
    // {
    //   path: "/user/all-document/form",
    //   name: "getAllDocumentForm",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetForm
    // },
    {
      path: "/user/all-document/doc-file",
      name: "getallDetailDocument",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: GetAllDetailDocument
    },
    // {
    //   path: "/user/my-document/info",
    //   name: "getMyDocument",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocument
    // },
    {
      path: "/user/document/doc-file",
      name: "getDetailDocument",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: GetDetailDocument
    },
    // {
    //   path: "/user/document/access",
    //   name: "getDocumentAccess",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocumentAccess
    // },
    // {
    //   path: "/user/document/expired",
    //   name: "getDocumentExpired",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocumentExpired
    // },


    // Risk Management
    // {
    //   path: "/user/risk/info",
    //   name: "getRisk",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRisk
    // },
    {
      path: "/risk/form",
      name: "formRisk",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRisk
    },
    {
      path: "/user/risk-identification/info",
      name: "getRiskIdentification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: GetRiskIdentification
    },
    {
      path: "/risk-identification/form",
      name: "formRiskIdentification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskIdentification
    },
    {
      path: "/risk-score/form",
      name: "formRiskScore",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskScore
    },
    {
      path: "/risk-control-detail/form",
      name: "formRiskControlDetail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskControlDetail
    },
    {
      path: "/risk-imp-plan/form",
      name: "formRiskImpPlan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskImpPlan
    },


    // Verifikator
    // Management Regulation
    // {
    //   path: "/verifier/request-document/info",
    //   name: "getRequestDocumentHead",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresHead: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRequestDocumentHead
    // },
    {
      path: "/verifier/request-document/history",
      name: "historyRequestDocumentHead",
      meta: {
        requiresAuth: true,
        // requiresHead: true,
        layout: defaultLayout
      },
      component: HistoryRequestDocumentHead
    },
    // {
    //   path: "/verifier/verif-document/info",
    //   name: "getVerifDocumentHead",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresHead: true,
    //     layout: defaultLayout
    //   },
    //   component: GetVerifDocumentHead
    // },
    {
      path: "/verifier/verif-detail-document/doc-file",
      name: "getVerifDetailDocumentHead",
      meta: {
        requiresAuth: true,
        // requiresHead: true,
        layout: defaultLayout
      },
      component: GetVerifDetailDocumentHead
    },
    {
      path: "/verifier/document/info",
      name: "getDocumentHead",
      meta: {
        requiresAuth: true,
        // requiresHead: true,
        layout: defaultLayout
      },
      component: GetDocumentHead
    },
    {
      path: "/verifier/detail-document/doc-file",
      name: "getDetailDocumentHead",
      meta: {
        requiresAuth: true,
        // requiresHead: true,
        layout: defaultLayout
      },
      component: GetDetailDocumentHead
    },

    // SDC
    // document management

    // {
    //   path: "/admin/template-docs/info",
    //   name: "getTemplateDocsAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetTemplateDocsAdmin
    // },
    {
      path: "/admin/template-docs/form",
      name: "formTemplateDocsAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: FormTemplateDocsAdmin
    },
    // {
    //   path: "/admin/request-document/info",
    //   name: "getRequestDocumentAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRequestDocumentAdmin
    // },
    // {
    //   path: "/admin/request-document/history",
    //   name: "historyRequestDocumentAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: HistoryRequestDocumentAdmin
    // },
    // {
    //   path: "/admin/document/FORM",
    //   name: "getDocFrmAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocFrmAdmin
    // },
    // {
    //   path: "/admin/document/KETENTUAN-PERUSAHAAN",
    //   name: "getDocKepPerAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocKepPerAdmin
    // },
    // {
    //   path: "/admin/document/SOP",
    //   name: "getDocSopAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocSopAdmin
    // },
    // {
    //   path: "/admin/document/WI",
    //   name: "getDocWiAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocWiAdmin
    // },
    {
      path: "/admin/document/add",
      name: "addDocumentAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: AddDocumentAdmin
    },
    {
      path: "/admin/document/doc-file",
      name: "detailDocumentAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: DetailDocumentAdmin
    },
    {
      path: "/admin/doc-file/add",
      name: "addDocumentFileAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: AddDocumentFileAdmin
    },
    {
      path: "/view-pdf",
      name: "viewPdf",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ViewPdf
    },
    // {
    //   path: "/admin/document/expired",
    //   name: "getDocExpired",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetDocExpired
    // },

    // Regulation List
    // {
    //   path: "/admin/regulation-list/info",
    //   name: "getRegulationListAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRegulationListAdmin
    // },
    // {
    //   path: "/admin/regulation-dash/info",
    //   name: "getRegulationDashAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRegulationDashAdmin
    // },
    {
      path: "/admin/regulation-detail",
      name: "getRegulationDetailAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: GetRegulationDetailAdmin
    },

    //MASTER
    {
      path: "/admin/user/add",
      name: "addUserAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AddUserAdmin
    },
    // {
    //   path: "/admin/account-active/info",
    //   name: "getAccountActiveAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetAccountActiveAdmin
    // },
    // {
    //   path: "/admin/account-not-active/info",
    //   name: "getAccountNotActiveAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetAccountNotActiveAdmin
    // },
    // {
    //   path: "/admin/account-verif/info",
    //   name: "getAccountVerifAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetAccountVerifAdmin
    // },
    // {
    //   path: "/admin/code/info",
    //   name: "getCodeAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: GetCodeAdmin
    // },
    {
      path: "/admin/code/company/add",
      name: "addCompanyCodeAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AddCompanyCodeAdmin
    },
    {
      path: "/admin/code/department/add",
      name: "addDepartmentCodeAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AddDepartmentCodeAdmin
    },

    // Risk Management
    // {
    //   path: "/admin/risk/info",
    //   name: "getRiskAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRiskAdmin
    // },
    {
      path: "/admin/risk-identification/info",
      name: "getRiskIdentificationAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskIdentificationAdmin
    },
    // {
    //   path: "/admin/risk-control-item/info",
    //   name: "getRiskControlItemAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRiskControlItemAdmin
    // },
    {
      path: "/risk-control-item/form",
      name: "formRiskControlItem",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskControlItem
    },
    {
      path: "/risk-score-indicator/form",
      name: "formRiskScoreIndicator",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskScoreIndicator
    },
    // {
    //   path: "/admin/risk-score/form",
    //   name: "formRiskScoreAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: FormRiskScoreAdmin
    // },
    // {
    //   path: "/admin/risk-control-detail/form",
    //   name: "formRiskControlDetailAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: FormRiskControlDetailAdmin
    // },
    // {
    //   path: "/admin/risk-imp-plan/form",
    //   name: "formRiskImpPlanAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: FormRiskImpPlanAdmin
    // },
    // {
    //   path: "/admin/risk-control/info",
    //   name: "getRiskControlAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRiskControlAdmin
    // },
    {
      path: "/risk-control/form",
      name: "formRiskControl",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskControl
    },
    // {
    //   path: "/admin/risk-severity/info",
    //   name: "getRiskSeverityAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     // requiresAdmin: true,
    //     layout: defaultLayout
    //   },
    //   component: GetRiskSeverityAdmin
    // },
    {
      path: "/risk-severity/form",
      name: "formRiskSeverity",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: FormRiskSeverity
    },
    {
      path: "/admin/asset/info",
      name: "getAssetAdmin",
      meta: {
        requiresAuth: true,
        // requiresAdmin: true,
        layout: defaultLayout
      },
      component: GetAssetAdmin
    },
    // {
    //   path: "/admin/audit-plan/info",
    //   name: "getAuditPlanAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditPlanAdmin
    // },
    // {
    //   path: "/user/audit-plan/info",
    //   name: "getAuditPlanUser",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditPlanUser
    // },
    {
      path: "/audit-plan/form",
      name: "formAuditPlanAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditPlanAdmin
    },
    // {
    //   path: "/admin/audit-checklist/info",
    //   name: "getAuditChecklistAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditChecklistAdmin
    // },
    {
      path: "/audit-checklist/form",
      name: "formAuditChecklistAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditChecklistAdmin
    },
    {
      path: "/audit-process/form",
      name: "formAuditProcessAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditProcessAdmin
    },
    {
      path: "/scope-activities/form",
      name: "formScopeActivitiesAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormScopeActivitiesAdmin
    },
    {
      path: "/pic-checklist/form",
      name: "formPicChecklistAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormPicChecklistAdmin
    },
    {
      path: "/potential-risk/form",
      name: "formPotentialRiskAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormPotentialRiskAdmin
    },
    {
      path: "/audit-risk-control/form",
      name: "formAuditRiskControlAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditRiskControlAdmin
    },
    {
      path: "/audit-verification-item/form",
      name: "formAuditVerificationItemAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditVerificationItemAdmin
    },
    // {
    //   path: "/admin/audit-report/info",
    //   name: "getAuditReportAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditReportAdmin
    // },
    // {
    //   path: "/admin/audit-report/info",
    //   name: "getAuditReportAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditReportAdmin
    // },
    // {
    //   path: "/admin/audit-history-report/info",
    //   name: "getAuditHistoryReportAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: getAuditHistoryReportAdmin
    // },
    // {
    //   path: "/user/audit-report/info",
    //   name: "getAuditReportUser",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditReportUser
    // },
    // {
    //   path: "/user/audit-history-report/info",
    //   name: "getAuditHistoryReportUser",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditHistoryReportUser
    // },
    // {
    //   path: "/admin/audit-finding-type/info",
    //   name: "getAuditFindingTypeAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditFindingTypeAdmin
    // },
    {
      path: "/audit-finding-type/form",
      name: "formAuditFindingType",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditFindingType
    },
    // {
    //   path: "/admin/audit-factor-finding/info",
    //   name: "getAuditFactorFindingAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditFactorFindingAdmin
    // },
    {
      path: "/audit-factor-finding/form",
      name: "formAuditFactorFinding",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditFactorFinding
    },
    // {
    //   path: "/admin/audit-aberration-standart/info",
    //   name: "getAuditAberrationStandartAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditAberrationStandartAdmin
    // },
    {
      path: "/audit-aberration-standart/form",
      name: "formAuditAberrationStandart",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditAberrationStandart
    },
    // {
    //   path: "/admin/audit-fault-classification/info",
    //   name: "getAuditfaultClassificationAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditfaultClassificationAdmin
    // },
    {
      path: "/audit-fault-classification/form",
      name: "formAuditfaultClassification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditfaultClassification
    },
    // {
    //   path: "/admin/audit-fault-type/info",
    //   name: "getAuditfaultTypeAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditfaultTypeAdmin
    // },
    {
      path: "/audit-fault-type/form",
      name: "formAuditfaultType",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditfaultType
    },
    {
      path: "/audit-report/form",
      name: "formAuditReportAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditReportAdmin
    },
    {
      path: "/audit-root-couse/form",
      name: "formRootCouseAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormRootCouseAdmin
    },
    {
      path: "/audit-correction/form",
      name: "formAuditCorrectionAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditCorrectionAdmin
    },
    {
      path: "/audit-prevective/form",
      name: "formAuditPrevectiveAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditPrevectiveAdmin
    },
    {
      path: "/audit-impl/form",
      name: "formAuditImplAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditImplAdmin
    },
    // {
    //   path: "/admin/audit-finding/info",
    //   name: "getAuditFindingAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetAuditFindingAdmin
    // },
    // {
    //   path: "/admin/audit-history-finding/info",
    //   name: "getHistoryAuditFindingAdmin",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout,
    //   },
    //   component: GetHistoryAuditFindingAdmin
    // },
    {
      path: "/audit-finding/form",
      name: "formAuditFindingAdmin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FormAuditFindingAdmin
    },
    {
      path: "/user/capa/info",
      name: "getCapaUser",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: GetCapaUser
    },
    {
      path: "/pivot",
      name: "getpivot",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Getpivot
    },


    // SUPER ADMIN
    // regulation
    {
      path: "/super-admin/regulation/info",
      name: "getRegulationSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRegulationSuAdmin
    },
    {
      path: "/super-admin/regulation-detail/info",
      name: "getRegulationDetailSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRegulationDetailSuAdmin
    },
    {
      path: "/super-admin/regulation-document/form",
      name: "formRegulationDocumentSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormRegulationDocumentSuAdmin
    },
    {
      path: "/super-admin/regulation-detail/form",
      name: "formRegulationDetailSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormRegulationDetailSuAdmin
    },
    {
      path: "/super-admin/regulation-impl/form",
      name: "formRegulationImplSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormRegulationImplSuAdmin
    },
    {
      path: "/super-admin/regulation/form",
      name: "formRegulationSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormRegulationSuAdmin
    },
    {
      path: "/super-admin/menu/info",
      name: "getMenuSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetMenuSuAdmin
    },
    {
      path: "/super-admin/menu/form",
      name: "formMenuSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormMenuSuAdmin
    },

    // request doc
    {
      path: "/super-admin/req-doc/info",
      name: "getReqDocSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetReqDocSuAdmin
    },
    {
      path: "/super-admin/history-req-doc/info",
      name: "getHistoryReqDocSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetHistoryReqDocSuAdmin
    },
    {
      path: "/super-admin/req-doc/form",
      name: "formReqDocSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormReqDocSuAdmin
    },
    {
      path: "/super-admin/document/info",
      name: "getDocumentSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetDocumentSuAdmin
    },
    {
      path: "/super-admin/document/form",
      name: "formDocumentSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormDocumentSuAdmin
    },
    {
      path: "/super-admin/document/doc-file/info",
      name: "getDocumentDetailSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetDocumentDetailSuAdmin
    },
    {
      path: "/super-admin/document/doc-file/form",
      name: "formDocumentDetailSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormDocumentDetailSuAdmin
    },
    {
      path: "/super-admin/code/info",
      name: "getCodeSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetCodeSuAdmin
    },
    {
      path: "/super-admin/code/company/form",
      name: "formCodeCompanySuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormCodeCompanySuAdmin
    },
    {
      path: "/super-admin/code/department/form",
      name: "formCodeDepartmentSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormCodeDepartmentSuAdmin
    },
    {
      path: "/super-admin/template-docs/info",
      name: "getTemplateDocsSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetTemplateDocsSuAdmin
    },
    {
      path: "/super-admin/template-docs/form",
      name: "formTemplateDocsSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: FormTemplateDocsSuAdmin
    },
    {
      path: "/super-admin/account-not-active/info",
      name: "getAccountNotActiveSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetAccountNotActiveSuAdmin
    },
    {
      path: "/super-admin/account-active/info",
      name: "getAccountActiveSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: AccountActiveSuAdmin
    },
    {
      path: "/super-admin/account-verif/info",
      name: "getAccountVerifSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetAccountVerifSuAdmin
    },
    {
      path: "/super-admin/risk/info",
      name: "getRiskSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskSuAdmin
    },
    {
      path: "/super-admin/risk-identification/info",
      name: "getRiskIdentificationSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskIdentificationSuAdmin
    },
    {
      path: "/super-admin/risk-control-item/info",
      name: "getRiskControlItemSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskControlItemSuAdmin
    },
    {
      path: "/super-admin/risk-severity/info",
      name: "getRiskSeveritySuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskSeveritySuAdmin
    },
    {
      path: "/super-admin/risk-control/info",
      name: "getRiskControlSuAdmin",
      meta: {
        requiresAuth: true,
        requireSuAdmin: true,
        layout: defaultLayout
      },
      component: GetRiskControlSuAdmin
    },
    {
      path: "/not-found",
      name: "not-found",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: notFound
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Masuk Aplikasi SDC"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Registrasi Akun"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    // {
    //   path: "/",
    //   redirect: "/login"
    // },
    {
      path: "/recovery",
      redirect: "/login"
    },
    {
      path: "/:pathMatch(.*)*",

      component: notFound,
      meta: {
        layout: SingleCard
      }
      // redirect: "/login"
    },
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {
  const token = Cookie.get('clientSecret');
  const rl = Cookie.get('rl');
  const secret = store.state.authentication.appSecret
  const routes = router.getRoutes();

  if (to.meta.requiresAuth) {
    if (!token) {
      next({ name: "login-form" })
    } else {
      try {
        let tokenData = token.split(".")[1];
        const decode = JSON.parse(atob(tokenData));
        const now = new Date().getTime() / 1000;

        if (decode.exp < now) {
          Cookie.remove('clientSecret');
          next({ name: "login-form" });
          // notify("Session Berakhir, Silahkan Login Kembali", "warning", 5000);
          return;
        } else {
          next();
        }
      } catch (error) {
        // Jika decoding token gagal (misalnya token rusak)
        Cookie.remove('clientSecret');
        next({ name: "login-form" });
        return;
      }
      // let tokenData = token.split(".")[1];
      // const decode = JSON.parse(atob(tokenData));
      // const now = new Date().getTime() / 1000;
      // if (decode.exp < now) {
      //   Cookie.remove('clientSecret')
      //   next({ name: "login-form" })
      //   // notify("Session Berakhir, Silahkan Login Kembali", "warning", 5000);
      //   return
      // } else {
      //   next()
      // }
    }

  } else if (!to.meta.requiresAuth && token) {
    next({ name: "not-found" })
  }
  else {
    next()
  }
});

let dynamicRoutes = [];

export async function addRoutesFromMenu() {
  try {
    const menus = localStorage.getItem('per_manage') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('per_manage'), 'secretKey').toString(CryptoJS.enc.Utf8)) : null;

    const routes = router.getRoutes();
    const routeExists = routes.some(route => route.name === 'someRouteName');
    menus.forEach(menu => {

      if (!routeExists) {
        // hanya menus yang mempunyai component yang di masukan ke routes
        if (menu.module.component_name) {
          router.addRoute({
            name: menu.module.id,
            path: menu.module.path,
            component: () => import(`.${menu.module.component_name}.vue`),
            meta: {
              requiresAuth: true,
              menuId: menu.module.id,
              permission: menu.permission,
              layout: defaultLayout
            },
            // beforeEnter: checkPermission // Middleware untuk cek permission
          });
          dynamicRoutes.push(menu.module.id);
        }
      } else {
        console.log(`Route already exists: ${menu.path}`);
      }
    });
  } catch (error) {
    console.error('Error fetching menu items:', error);
  }
}

export function resetDynamicRoutes() {
  // Hapus semua rute dinamis yang ditambahkan
  dynamicRoutes.forEach(routeName => {
    router.removeRoute(routeName);
  });
  dynamicRoutes = [];
}

// calling method added dynamic router
if (localStorage.getItem('per_manage')) {
  addRoutesFromMenu()
}

export default router;
